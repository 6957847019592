<template>
	<div class="settings">
		<van-nav-bar
			class="bar"
			:border="false"
			:title="$t('bank.bank_card_management')"
			left-arrow
			@click-left="onClickLeft"
		/>
		<h3 class="pc-title">{{ $t("bank.bank_card_management") }}</h3>
		<van-cell-group class="cell-group" :border="false">
			<van-field
				class="settings-cell"
				label-width="170"
				:label="$t('bank.label1')"
				:disabled="isExist"
				v-model="bank_name"
				:placeholder="$t('bank.placeholder1')"
			/>
			<van-field
				class="settings-cell"
				label-width="170"
				:label="$t('bank.label2')"
				:disabled="isExist"
				v-model="card_number"
				:placeholder="$t('bank.placeholder2')"
				@copy="_returnEvent"
				@paste="_returnEvent"
			/>
			<van-field
				class="settings-cell"
				label-width="170"
				:label="$t('bank.label3')"
				:disabled="isExist"
				v-model="card_name"
				:placeholder="$t('bank.placeholder3')"
				@copy="_returnEvent"
				@paste="_returnEvent"
			/>
		</van-cell-group>

		<div class="login-password__btn">
			<van-button
				type="primary"
				color="linear-gradient( 270deg, #A44AFB 0%, #F85DC6 100%)"
				block
				round
				@click="onSubmit"
				:loading="loading"
				:disabled="isExist"
				>{{ $t("login.submit") }}</van-button
			>
		</div>
	</div>
</template>

<script>
import { addBankCard, getBankCard } from "@/api";
const env_lang = process.env.VUE_APP_LANG;

export default {
	data() {
		return {
			bank_name: "",
			card_number: "",
			card_name: "",
			isExist: false,
			loading: false,
		};
	},
	mounted() {
		this._getBankCard();
		// 未设置支付密码
		if (!this.$store.state.user.has_pay_password) {
			this.$router.push("/cash_password");
		}
	},
	methods: {
		_returnEvent(event) {
			event.preventDefault();
		},
		_getBankCard() {
			getBankCard().then(({ data }) => {
				if (data) {
					this.isExist = true;
					this.bank_name = data.bank_name;
					this.card_number = data.card_number;
					this.card_name = data.card_name;
				}
			});
		},
		onClickLeft() {
			this.$router.back();
		},
		onSubmit() {
			if (!this.bank_name) {
				this.$toast(this.$t("bank.placeholder1"));
				return;
			}
			if (!this.card_number) {
				this.$toast(this.$t("bank.placeholder2"));
				return;
			}
			if (!this.card_name) {
				this.$toast(this.$t("bank.placeholder3"));
				return;
			}
			// if (env_lang == 'vi_VN') {

			//     if (this.card_number.length < 6 || this.card_number.length > 20) {
			//         this.$toast(this.$t('bank.card_number_error'));
			//         return;
			//     }
			// } else {

			//     if (this.card_number.length < 10 || this.card_number.length > 13) {
			//         this.$toast(this.$t('bank.card_number_error'));
			//         return;
			//     }
			// }
			this.loading = true;
			addBankCard({
				bank_name: this.bank_name,
				card_number: this.card_number,
				card_name: this.card_name,
			}).then(({ code }) => {
				this.loading = false;
				if (code === 200) {
					this.$toast(this.$t("bank.add_successfully"));
					setTimeout(() => {
						this.$router.back();
					}, 1500);
				} else {
					this.$toast(this.$t("bank.add_failure"));
				}
			});
		},
	},
};
</script>

<style lang="less" scoped>
.login-password__btn {
	padding: 24px;
}
.bar /deep/ .van-nav-bar__left .van-icon {
	color: var(--font-color);
}
.bar /deep/ .van-nav-bar__title {
	color: var(--font-color);
}
.settings {
	min-height: 100vh;
	background: url(../../../assets/login_bg.png) left top no-repeat
		var(--bg-color);
	background-size: 100%;
	.bar {
		background: none;
	}
	.cell-group {
		background: none;
	}
	.settings-cell {
		margin: 14px 16px;
		width: 343px;
		height: 48px;
		background: #ffffff;
		border-radius: 23px 23px 23px 23px;
	}
}
.pc-title {
	display: none;
}
@media only screen and (min-width: 750px) {
	.settings {
		background: none;
		padding: 42px;
		.bar {
			display: none;
		}
		.pc-title {
			display: block;
			font-weight: 800;
			font-size: 28px;
			color: var(--font-color);
		}
		.cell-group {
			width: 78%;
			margin: 50px auto;
			.settings-cell {
				margin: 20px 0;
				width: 100%;
				height: 78px;
				line-height: 78px;
				background: #f0f3f8;
				border-radius: 16px;
				font-size: 16px;
				color: #666666;
				padding: 0 25px;
				/deep/ .van-field__control {
					height: 78px;
				}
			}
		}
		.login-password__btn {
			padding: 0;
			display: flex;
			justify-content: center;
			.van-button {
				width: 319px;
				height: 60px;
				line-height: 60px;
				background: linear-gradient(270deg, #a44afb 0%, #f85dc6 100%);
				border-radius: 55px 55px 55px 55px;
				font-size: 16px;
				color: #ffffff;
			}
		}
	}
}
</style>
